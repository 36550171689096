import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';

interface IProgressBarProps {
    countPage: number;
}

const ProgressBarComp: React.FC<IProgressBarProps> = ({ countPage }) => {
    useEffect(() => {
        const prElement = document.querySelector('.progressBar');
        const percent = (countPage / 4) * 100;
        if (prElement) prElement.setAttribute('style', `height: ${percent}%`);
    }, [countPage]);
    return <div className="progressBar"></div>;
};

export const ProgressBar = connect(
    (state) => ({ countPage: state.AppMainReducer.currentSectionCount }),
    (dispatch) => ({})
)(ProgressBarComp);
