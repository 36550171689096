import React from 'react';
import './App.scss';
import { Main } from './pages/main';

export const App = () => {
    return (
        <div>
            <Main />
        </div>
    );
};
