import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { CloseBtn } from '../../others/close-btn/close-btn';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IHotelSubProps {
    isShow: Boolean;
    tooglePopup: Function;
    toogleReserMob: Function;
}

const HotelSubMComp: React.FC<IHotelSubProps> = ({
    isShow,
    tooglePopup,
    toogleReserMob,
}) => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className={isShow ? 'hotel-sub-mob active' : 'hotel-sub-mob'}>
            <div className="hotel-sub-mob-header">
                <div className="hotel-sub-mob-header-title--block">
                    <h4 className="hotel-sub-mob-header-title">
                        Grand Deluxe Room
                    </h4>
                    <p className="hotel-sub-mob-header-count">2 номера</p>
                </div>
                <CloseBtn
                    className="hotel-sub-mob-header-close"
                    onClick={() => {
                        tooglePopup(false);
                    }}
                />
            </div>
            <div className="hotel-sub-mob-content">
                <div className="hotel-sub-mob-slider">
                    <Slider {...settings}>
                        <div className="hotel-sub-mob-slider__item"></div>
                        <div className="hotel-sub-mob-slider__item"></div>
                    </Slider>
                </div>
            </div>
            <p className="hotel-sub-mob-price">
                290 000 ₽ <span>На одного человека</span>
            </p>
            <p className="hotel-sub-mob-desc">
                Два этажа, одна спальная, собственный бассейн и максимальная
                уединенность
            </p>
            <ul className="hotel-sub-mob-options-list">
                <li className="hotel-sub-mob-options-list__item">
                    – Площадь 205 кв.м
                </li>
                <li className="hotel-sub-mob-options-list__item">
                    – Площадь 205 кв.м
                </li>
                <li className="hotel-sub-mob-options-list__item">
                    – Площадь 205 кв.м
                </li>
                <li className="hotel-sub-mob-options-list__item">
                    – Площадь 205 кв.м
                </li>
                <li className="hotel-sub-mob-options-list__item">
                    – Площадь 205 кв.м
                </li>
            </ul>
            <button
                className="hotel-sub-mob-btn"
                onClick={() => toogleReserMob(true)}
            >
                Забронировать
            </button>
        </div>
    );
};

export const HotelSubM = connect(
    (state) => ({ isShow: state.AppMainReducer.isHotelSubMob }),
    (dispatch) => ({
        tooglePopup: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_HOTEL_SUB', payload: val });
        },
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
    })
)(HotelSubMComp);
