import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';

export interface IHeaderProps {
    currentAnchor: string;
    setUpAnchor: Function;
    toogleMobileMenu: Function;
    openReservationPopup: Function;
}

const HeaderComp: React.FC<IHeaderProps> = ({
    currentAnchor,
    setUpAnchor,
    toogleMobileMenu,
    openReservationPopup,
}) => {
    return (
        <header className="header">
            <div className="header-navigation container">
                <p className="header-logo">Transformator travel</p>
                <nav className="header-nav-list">
                    <a
                        href="/#new-year-bali-sub"
                        className={
                            currentAnchor === 'new-year-bali-sub'
                                ? 'font-sm header-nav-list__link active'
                                : 'font-sm header-nav-list__link'
                        }
                        onClick={() => setUpAnchor('new-year-bali-sub')}
                    >
                        О мероприятии
                        <div className="header-nav-list__link--hover-line"></div>
                    </a>
                    <a
                        href="/#gallery"
                        className={
                            currentAnchor === 'gallery'
                                ? 'font-sm header-nav-list__link active'
                                : 'font-sm header-nav-list__link'
                        }
                        onClick={() => setUpAnchor('gallery')}
                    >
                        Тусовки
                        <div className="header-nav-list__link--hover-line"></div>
                    </a>
                    <a
                        href="/#hotel"
                        className={
                            currentAnchor === 'hotel'
                                ? 'font-sm header-nav-list__link active'
                                : 'font-sm header-nav-list__link'
                        }
                        onClick={() => setUpAnchor('hotel')}
                    >
                        Номера и тарифы
                        <div className="header-nav-list__link--hover-line"></div>
                    </a>
                    <a
                        href="/#activity"
                        className={
                            currentAnchor === 'activity'
                                ? 'font-sm header-nav-list__link active'
                                : 'font-sm header-nav-list__link'
                        }
                        onClick={() => setUpAnchor('activity')}
                    >
                        Активности
                        <div className="header-nav-list__link--hover-line"></div>
                    </a>
                    <a
                        href="/#activity"
                        className="font-sm header-nav-list__link"
                        onClick={(e) => {
                            e.preventDefault();
                            openReservationPopup('');
                        }}
                    >
                        Подать заявку
                        <div className="header-nav-list__link--hover-line"></div>
                    </a>
                </nav>
                <div className="header-phone">
                    <a href="tel: +7 925 900 90 90">
                        <img
                            src={require('../../../static/images/header/phone.png')}
                            alt="phone"
                            className="phone-icon-header"
                        />
                    </a>
                    <a
                        href="tel: +7 925 900 90 90"
                        className="header-phone__text"
                    >
                        +7 925 900 90 90
                    </a>
                </div>
                <div
                    className="header-burger"
                    onClick={(e) => toogleMobileMenu(true)}
                >
                    <div className="header-burger__line"></div>
                    <div className="header-burger__line"></div>
                </div>
            </div>
        </header>
    );
};

export const Header = connect(
    (state) => ({ currentAnchor: state.AppMainReducer.currentAnchor }),
    (dispatch) => ({
        setUpAnchor: (value) => {
            dispatch({ type: 'SET_UP_ANCHOR', payload: { anchor: value } });
        },
        toogleMobileMenu: (value) => {
            dispatch({ type: 'TOOGLE_MOBILE_MENU', payload: value });
        },
        openReservationPopup: (currentHotel) => {
            dispatch({
                type: 'OPEN_RESERVATION_POPUP',
                payload: { currentHotel },
            });
        },
    })
)(HeaderComp);
