import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';

interface INewYearBaliProps {
    isMobile?: Boolean;
    toogleReserMob: Function;
    api: {
        moveSectionDown: Function;
    };
}

const NewYearBaliComp: React.FC<INewYearBaliProps> = ({
    toogleReserMob,
    api,
}) => {
    return (
        <div className="new-year-bali section">
            <div className="new-year-bali-content container">
                <p className="font-md new-year-bali-date">28.12-03.01</p>
                <img
                    src={require('../../../static/images/new-year-bali/title.svg')}
                    alt="title"
                    className="new-year-bali-title"
                />
                <img
                    src={require('../../../static/images/new-year-bali/title_mob.svg')}
                    alt="title"
                    className="new-year-bali-title_mob"
                />
                <div className="one-million-block">
                    <img
                        src={require('../../../static/images/new-year-bali/one-million.svg')}
                        alt="one million"
                    />
                    <p className="font-md one-million-text">на развлечения</p>
                </div>
                <div className="learn-more-block">
                    <p className="learn-more-text">Узнать больше</p>
                    <img
                        src={require('../../../static/images/new-year-bali/arrow-down_mob.svg')}
                        alt="arrow down"
                        className="bali-arrow-down_mob"
                    />
                    <button
                        className="learn-more-btn_mob"
                        onClick={() => toogleReserMob(true)}
                    >
                        Забронировать
                    </button>
                    <button
                        className="learn-more-btn"
                        onClick={() => {
                            api.moveSectionDown();
                        }}
                    >
                        <img
                            src={require('../../../static/images/new-year-bali/arrow-down.svg')}
                            alt="arrow down"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export const NewYearBali = connect(
    (state) => ({
        isMobile: state.AppMainReducer.isMobile,
    }),
    (dispatch) => ({
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
    })
)(NewYearBaliComp);
