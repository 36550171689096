import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { IFaqPopup } from '../../../../types';
import { CloseBtn } from '../../others/close-btn/close-btn';

interface IFaqPopupProps {
    faqPopup: IFaqPopup;
    tooglePopup: Function;
    setUpQuestion: Function;
}

const FaqPopupComp: React.FC<IFaqPopupProps> = ({
    faqPopup,
    tooglePopup,
    setUpQuestion,
}) => {
    const mainClass = () => {
        if (faqPopup.isShow) return 'faq-popup active';
        else return 'faq-popup';
    };

    const leftSideClass = () => {
        if (faqPopup.currentBg) return 'faq-popup-left-side active';
        else return 'faq-popup-left-side';
    };

    const setUpBg = () => {
        return {
            background: `url('${
                faqPopup.currentBg ||
                require('../../../../static/images/faqPopup/2.png')
            }')`,
        };
    };

    const questionClass = (question) => {
        if (faqPopup.currentQuestion === question)
            return 'font-md faq-popup-right-side-question-list__item active';
        else return 'font-md faq-popup-right-side-question-list__item';
    };

    return (
        <div className={mainClass()}>
            <div className={leftSideClass()} style={setUpBg()}>
                <p className="font-md faq-popup-question-text">
                    {faqPopup.currentText || ''}
                </p>
            </div>
            <div className="faq-popup-right-side">
                <h4 className="font-sm faq-popup-right-side-title">
                    Часто задаваемые вопросы
                </h4>
                <CloseBtn
                    className="faq-popup-close-btn"
                    onClick={() => tooglePopup(false)}
                />
                <ul className="faq-popup-right-side-question-list">
                    <li
                        className={questionClass(1)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/1.png'),
                                text:
                                    'Нет, не включен. Но мы будем фрахватоть целый борт для прямого перелета из Москвы на Бали и предложим вам максимально приятную стоимость для бронирования авиабилета. Предварительная дата вылета туда - 25 декабря.',
                                question: 1,
                            })
                        }
                    >
                        Включен ли в стоимость авиабилет?
                    </li>
                    <li
                        className={questionClass(2)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/2.png'),
                                text:
                                    'В стоимость включены прекрасные завтраки в ресторане отеля Kempinsky. Включены обеда на активных выездах (восхождения и т. п.). Включены вечерние фуршеты на ежедневных вечеринках. Не включены обеды и ужины в ресторанах в отеле.',
                                question: 2,
                            })
                        }
                    >
                        Что с питанием? Включена ли еда?
                    </li>
                    <li
                        className={questionClass(3)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/3.png'),
                                text:
                                    'В стоимость включены все трансферы между нашим отелем и активностями. Например, ежедневные выезды на серфинг, в баню и так далее.',
                                question: 3,
                            })
                        }
                    >
                        Включены ли в стоимость трансферы?
                    </li>
                    <li
                        className={questionClass(4)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/4.png'),
                                text:
                                    'Нет, не включен. Но мы будем фрахватоть целый борт для прямого перелета из Москвы на Бали и предложим вам максимально приятную стоимость для бронирования авиабилета. Предварительная дата вылета туда - 25 декабря.',
                                question: 4,
                            })
                        }
                    >
                        Могу ли я просто забронировать номер и тусоваться вместе
                        с вами?
                    </li>
                    <li
                        className={questionClass(5)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/5.png'),
                                text:
                                    'Нет, не включен. Но мы будем фрахватоть целый борт для прямого перелета из Москвы на Бали и предложим вам максимально приятную стоимость для бронирования авиабилета. Предварительная дата вылета туда - 25 декабря.',
                                question: 5,
                            })
                        }
                    >
                        Сколько доплата за детей?
                    </li>
                    <li
                        className={questionClass(6)}
                        onClick={() =>
                            setUpQuestion({
                                bg: require('../../../../static/images/faqPopup/6.png'),
                                text:
                                    'Здесь есть несколько вариантов. Няня может размещаться в отдельном номере (по себестоимости номера), либо няня может жить с вами и детьми на вилле (вы просто целиком выкупаете виллу)',
                                question: 6,
                            })
                        }
                    >
                        Сколько доплата за няню? Она не будет участвовать в
                        ваших мероприятиях и тусовках?
                    </li>
                </ul>
            </div>
        </div>
    );
};

export const FaqPopup = connect(
    (state) => ({
        faqPopup: state.AppMainReducer.faqPopup,
    }),
    (dispatch) => ({
        tooglePopup: (value) => {
            dispatch({ type: 'TOOGLE_FAQ_POPUP', payload: { value } });
        },
        setUpQuestion: ({ bg, text, question }) => {
            dispatch({
                type: 'SET_UP_QUESTION_FAQ_POPUP',
                payload: {
                    content: {
                        currentBg: bg,
                        currentText: text,
                        currentQuestion: question,
                    },
                },
            });
        },
    })
)(FaqPopupComp);
