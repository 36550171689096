import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import './styles/main.scss';

interface IinputProps {
    placeholder: string;
    id: string;
    name: string;
    type: string;
    onChange(event: React.FormEvent<HTMLInputElement>): void;
    value: string;
    className?: string;
    mask?: string;
}

export const Tinput: React.FC<IinputProps> = ({
    placeholder,
    id,
    name,
    type,
    value,
    onChange,
    className,
    mask,
}) => {
    const [placeholderClass, setplaceholderClass] = useState(
        't-input-placeholder'
    );

    const mainClass = ['t-input'];

    if (className) mainClass.push(className);

    const setUpActive = () => {
        setplaceholderClass('t-input-placeholder active');
    };

    const setUpDisActive = () => {
        if (value) setplaceholderClass('t-input-placeholder active');
        else setplaceholderClass('t-input-placeholder');
    };
    return (
        <div className={mainClass.join(' ')}>
            <label
                htmlFor="testid"
                className={'font-md ' + placeholderClass + ' t-inpu-lavel'}
            >
                {placeholder}
            </label>
            <InputMask
                mask={mask}
                id={id}
                type={type}
                name={name}
                value={value}
                onChange={(e) => onChange(e)}
                className="font-md t-input--input"
                onFocus={(e) => {
                    e.preventDefault();
                    setUpActive();
                }}
                onBlur={() => {
                    setUpDisActive();
                }}
            />

            {/* <input
                
            /> */}
        </div>
    );
};
