import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { ImagePicker } from '../../imagePicker';
import { CloseBtn } from '../../others/close-btn/close-btn';

const bgStyle = (imgSrc: string) => {
    return { background: `url(${imgSrc})` };
};

interface IHotelPopupProps {
    closePopup: Function;
    hotelPopup: {
        isShow: Boolean;
        price: string;
        description: string;
        optionsList: Array<string>;
    };
    openReservationPopup: Function;
}

const HotelPopupComp: React.FC<IHotelPopupProps> = ({
    hotelPopup,
    closePopup,
    openReservationPopup,
}) => {
    const [bgImage, setbgImage] = useState({
        image: require('../../../../static/images/hotel-popup/bg.png'),
    });

    const setUpNewBg = (position) => {
        setbgImage({
            image: require(`../../../../static/images/hotel-popup/${position}.png`),
        });
    };
    const images = [
        { src: require('../../../../static/images/hotel-popup/sm-1.png') },
        { src: require('../../../../static/images/hotel-popup/sm-2.png') },
        { src: require('../../../../static/images/hotel-popup/sm-3.png') },
        { src: require('../../../../static/images/hotel-popup/sm-4.png') },
        { src: require('../../../../static/images/hotel-popup/sm-5.png') },
    ];

    const hotelPopupClass = () => {
        if (hotelPopup.isShow) return 'hotel-popup active';
        else return 'hotel-popup ';
    };
    const isActive = () => {
        if (hotelPopup.isShow) return 'active';
        else return '';
    };

    return (
        <div className={'hotel-popup-wrapper ' + isActive()}>
            <div
                className={'gallery-content--wrapper ' + hotelPopupClass()}
                style={bgStyle(bgImage.image)}
            >
                <div className="hotel-popup-content-wrapper container">
                    <CloseBtn
                        className="hotel-popup-close-btn"
                        onClick={() => closePopup()}
                    />
                    <div className="hotel-popup-content">
                        <h3 className="font-md-l hotel-popup--price">
                            {hotelPopup.price + ' ₽'}
                        </h3>
                        <p className="font-md hotel-popup-description">
                            {hotelPopup.description}
                        </p>
                        <ul className="hotel-popup--list">
                            {hotelPopup.optionsList.map((option, index) => {
                                return (
                                    <li
                                        className="font-sm hotel-popup--list__item"
                                        key={'hotel-option' + index}
                                    >
                                        {'–' + option}
                                    </li>
                                );
                            })}
                        </ul>
                        <p className="font-sm hotel-popup-extra-text">
                            * Эксклюзивный доступ в Villa Lounge
                        </p>
                        <div className="hotel-popup--buttons">
                            <button
                                className="font-sm hotel-popup-button--orange"
                                onClick={() => openReservationPopup('')}
                            >
                                Забронировать
                            </button>
                            <button className="font-sm hotel-popup-button--white">
                                осталось 2 номера
                            </button>
                        </div>
                    </div>
                </div>
                <ImagePicker onPick={setUpNewBg} images={images} />
            </div>
        </div>
    );
};

export const HotelPopup = connect(
    (state) => ({
        hotelPopup: state.AppMainReducer.hotelPopup,
    }),
    (dispatch) => ({
        closePopup: () => dispatch({ type: 'CLOSE_HOTEL_POPUP' }),
        openReservationPopup: (currentHotel) => {
            dispatch({
                type: 'OPEN_RESERVATION_POPUP',
                payload: { currentHotel },
            });
        },
    })
)(HotelPopupComp);
