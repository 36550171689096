import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { CloseBtn } from '../../others/close-btn/close-btn';

interface IMobileMenuProps {
    isShow: Boolean;
    toogleMenu: Function;
    toogleReserMob: Function;
    toogleFaqMob: Function;
}

const MobileMenuComp: React.FC<IMobileMenuProps> = ({
    isShow,
    toogleMenu,
    toogleReserMob,
    toogleFaqMob,
}) => {
    return (
        <div className={isShow ? 'mobile-menu active' : 'mobile-menu'}>
            <img
                src={require('../../../../static/images/mobile-menu/bg.png')}
                alt="bg"
                className="mobile-menu-bg-img"
            />
            <div className="mobile-menu-header">
                <p className="mobile-menu-title">Меню</p>
                <CloseBtn
                    className="mobile-menu-close"
                    onClick={() => {
                        toogleMenu(false);
                    }}
                />
            </div>
            <ul className="mobile-menu-links">
                <li className="mobile-menu-links__item">
                    <a
                        href="#new-year-bali-sub"
                        className="mobile-menu-links__item--link"
                        onClick={() => toogleMenu(false)}
                    >
                        О мероприятии
                    </a>
                </li>
                <li className="mobile-menu-links__item">
                    <a
                        href="#gallery"
                        className="mobile-menu-links__item--link"
                        onClick={() => toogleMenu(false)}
                    >
                        Тусовки
                    </a>
                </li>
                <li className="mobile-menu-links__item">
                    <a
                        href="#hotel"
                        className="mobile-menu-links__item--link"
                        onClick={() => toogleMenu(false)}
                    >
                        Номера и тарифы
                    </a>
                </li>
                <li className="mobile-menu-links__item">
                    <a
                        href="#activity"
                        className="mobile-menu-links__item--link"
                        onClick={() => toogleMenu(false)}
                    >
                        Активности
                    </a>
                </li>
                <li className="mobile-menu-links__item">
                    <a
                        href="#hotel"
                        className="mobile-menu-links__item--link"
                        onClick={() => {
                            toogleMenu(false);
                            toogleReserMob(true);
                        }}
                    >
                        Подать заявку
                    </a>
                </li>
                <li className="mobile-menu-links__item">
                    <a
                        href="/#"
                        className="mobile-menu-links__item--link"
                        onClick={(e) => {
                            e.preventDefault();
                            toogleMenu(false);
                            toogleFaqMob(true);
                        }}
                    >
                        FAQ
                    </a>
                </li>
            </ul>
            <div className="mobile-always-on-call">
                <p className="mobile-always-on-call-text">Всегда на связи</p>
                <a
                    href="tel: +7 925 900 90 90"
                    className="mobile-always-on-call-text-link"
                >
                    +7 925 900 90 90
                </a>
            </div>
        </div>
    );
};

export const MobileMenu = connect(
    (state) => ({ isShow: state.AppMainReducer.isMobileMenu }),
    (dispatch) => ({
        toogleMenu: (value) => {
            dispatch({ type: 'TOOGLE_MOBILE_MENU', payload: value });
        },
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
        toogleFaqMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_FAQ', payload: val });
        },
    })
)(MobileMenuComp);
