import React from 'react';
import './styles/main.scss';

interface ICloseBtnProps {
    onClick: Function;
    className: string;
}

export const CloseBtn: React.FC<ICloseBtnProps> = ({ onClick, className }) => {
    return (
        <button className={className + ' close-btn'} onClick={() => onClick()}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
            >
                <line
                    x1="35.1633"
                    y1="12.1925"
                    x2="12.1926"
                    y2="35.1631"
                    stroke="white"
                    strokeWidth="2"
                    className="faq-btn-line"
                />
                <line
                    x1="33.749"
                    y1="35.1632"
                    x2="10.7783"
                    y2="12.1925"
                    stroke="white"
                    strokeWidth="2"
                    className="faq-btn-line"
                />
            </svg>
        </button>
    );
};
