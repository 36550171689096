import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { Tinput } from '../../others/t-input/t-input';

interface IReservMobProps {
    isShow: Boolean;
    toogleReserv: Function;
}

const ReservMobComp: React.FC<IReservMobProps> = ({ isShow, toogleReserv }) => {
    const [StartedY, setStartedY] = useState(0);
    const [CurrentY, setCurrentY] = useState(0);
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        phone: '',
    });
    return (
        <div
            className={isShow ? 'reserv-mob active' : 'reserv-mob'}
            onTouchStart={(e) => {
                setStartedY((e.touches as any)[0].clientY);
            }}
            onTouchMove={(e) => {
                setCurrentY((e.changedTouches as any)[0].clientY);
                if (CurrentY - StartedY >= 120) {
                    toogleReserv(false);
                    setStartedY(0);
                    setCurrentY(0);
                }
            }}
            onTouchEnd={(e) => {
                setStartedY(0);
                setCurrentY(0);
            }}
        >
            <div className="reserv-mob-line"></div>
            <div className="reserv-mob-content">
                <Tinput
                    placeholder="Ваше имя"
                    id="name-inp-m"
                    name="name-m"
                    type="text"
                    value={inputs.name}
                    onChange={(e) => {
                        setInputs({
                            ...inputs,
                            name: (e.target as any).value,
                        });
                    }}
                    className="reserv-mob-inp"
                />
                <Tinput
                    placeholder="+7 Телефон"
                    mask={'+7(999)-999-99-99'}
                    id="phone-inp-m"
                    name="phone-m"
                    type="text"
                    value={inputs.phone}
                    onChange={(e) => {
                        setInputs({
                            ...inputs,
                            phone: (e.target as any).value,
                        });
                    }}
                    className="reserv-mob-inp"
                />
                <Tinput
                    placeholder="Email"
                    id="email-inp-m"
                    name="email-m"
                    type="text"
                    value={inputs.email}
                    onChange={(e) => {
                        setInputs({
                            ...inputs,
                            email: (e.target as any).value,
                        });
                    }}
                    className="reserv-mob-inp"
                />
            </div>
            <button className="reserv-mob-btn">Забронировать</button>
        </div>
    );
};

export const ReservMob = connect(
    (state) => ({ isShow: state.AppMainReducer.isReservMob }),
    (dispatch) => ({
        toogleReserv: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
    })
)(ReservMobComp);
