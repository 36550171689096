import React, { useEffect, useState } from 'react';
import './styles/main.scss';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EHotelType } from '../../../types';

export interface IHotelProps {
    hotelType: EHotelType;
    openPopup: Function;
    toogleHotelPopup: Function;
    changeHotelType: Function;
}

const bgStyle = (imgSrc: string) => {
    return { background: `url(${imgSrc})` };
};

const HotelMainComp: React.FC<IHotelProps> = ({
    hotelType,
    openPopup,
    toogleHotelPopup,
    changeHotelType,
}) => {
    const [contentClass, setcontentClass] = useState('hotel-content');
    const [mouseDownX, setmouseDownX] = useState(null);
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };

    useEffect(() => {
        let timer1, timer2;
        if (hotelType === 'first') {
            timer1 = setTimeout(() => {
                setcontentClass('hotel-content scrolled');
            }, 1000);
        }
        if (hotelType === 'scrolled') {
            timer2 = setTimeout(() => {
                setcontentClass('hotel-content scrolled');
            }, 1000);
        }
        if (hotelType === 'initial') {
            setcontentClass('hotel-content');
        }
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, [hotelType, contentClass]);

    const renderSliderItem = (imgSrc) => {
        return (
            <div
                className="hotel-slider__item"
                onMouseDown={(e) => {
                    setmouseDownX((e as any).clientX);
                }}
                onClick={(e) => {
                    if (mouseDownX === (e as any).clientX) {
                        openPopup({
                            price: '320 000',
                            description:
                                'Два этажа, одна спальная, собственный бассейн и максимальная уединенность.',
                            optionsList: [
                                'Площадь 205 кв.м',
                                'Размещение: 2 взрослых + 1 ребенок',
                                'досуп ко всем мероприятиям',
                                'функцией TIME CONTROL',
                            ],
                        });
                    }
                }}
            >
                <div
                    className="hotel-slider__item--wrapper"
                    style={bgStyle(
                        require(`../../../static/images/hotel/slide-${imgSrc}.png`)
                    )}
                >
                    <p className="font-md hotel-price-text">
                        290 000 ₽ <br />
                        <span className="font-sm hotel-price-description">
                            на одного человека
                        </span>
                    </p>
                    <h3 className="font-md-l hotel-slider-title">
                        Grand Deluxe Room
                    </h3>
                    <p className="font-sm hotel-slider-more">Подробнее</p>
                    {/* <button
                        className="rounded-button-md hotel-slider-watch-btn"
                        onClick={() =>
                            openPopup({
                                price: '320 000',
                                description:
                                    'Два этажа, одна спальная, собственный бассейн и максимальная уединенность.',
                                optionsList: [
                                    'Площадь 205 кв.м',
                                    'Размещение: 2 взрослых + 1 ребенок',
                                    'досуп ко всем мероприятиям',
                                    'функцией TIME CONTROL',
                                ],
                            })
                        }
                    >
                        <img
                            src={require('../../../static/images/hotel/eye.svg')}
                            alt="eye"
                        />
                    </button> */}
                </div>
            </div>
        );
    };

    return (
        <div className="hotel section">
            <div className={contentClass}>
                <div className="hotel-header">
                    <img
                        src={require('../../../static/images/hotel/rate.svg')}
                        alt="rate"
                        className="hotel-rate-icon"
                    />
                    <h2 className="font-lg hotel-title">Kempinski Bali</h2>
                    <p className="font-md hotel-description">
                        Apurva Kempinski Bali коллекция из 475 знаковых номеров,
                        люксови вилл, полностью забронирована под нас.
                    </p>
                </div>

                <div className="hotel-slider">
                    {' '}
                    <Slider {...settings}>
                        {renderSliderItem('1')}
                        {renderSliderItem('2')}
                        {renderSliderItem('3')}
                    </Slider>
                </div>
            </div>
            <div className="hotel-mobile">
                <div className="hotel-mobile-content">
                    <div className="hotel-mobile-header">
                        <p className="hotel-mobile-description">
                            Роскошный, пятизвездочный отель с эксклюзивными
                            номерами и виллами полностью закрыт для вас.{' '}
                        </p>
                        <img
                            src={require('../../../static/images/hotel/rate_mob.svg')}
                            alt="rate"
                            className="hotel-mobile-rate"
                        />
                        <h3 className="hotel-mobile-title">Kempinski Bali</h3>
                    </div>
                    <ul className="hotel-mobile-cards">
                        <li
                            className="hotel-mobile-cards__item"
                            style={bgStyle(
                                require(`../../../static/images/hotel/slide-1.png`)
                            )}
                            onClick={() => toogleHotelPopup(true)}
                        >
                            <h4 className="hotel-mobile-cards--title">
                                Grand Deluxe Room
                            </h4>
                            <p className="hotel-mobile-cards--price">
                                290 000 ₽
                            </p>
                        </li>
                        <li
                            className="hotel-mobile-cards__item"
                            style={bgStyle(
                                require(`../../../static/images/hotel/slide-2.png`)
                            )}
                            onClick={() => toogleHotelPopup(true)}
                        >
                            <h4 className="hotel-mobile-cards--title">
                                Grand Deluxe Room
                            </h4>
                            <p className="hotel-mobile-cards--price">
                                290 000 ₽
                            </p>
                        </li>
                        <li
                            className="hotel-mobile-cards__item"
                            style={bgStyle(
                                require(`../../../static/images/hotel/slide-3.png`)
                            )}
                            onClick={() => toogleHotelPopup(true)}
                        >
                            <h4 className="hotel-mobile-cards--title">
                                Grand Deluxe Room
                            </h4>
                            <p className="hotel-mobile-cards--price">
                                290 000 ₽
                            </p>
                        </li>
                        <li
                            className="hotel-mobile-cards__item"
                            style={bgStyle(
                                require(`../../../static/images/hotel/slide-3.png`)
                            )}
                            onClick={() => toogleHotelPopup(true)}
                        >
                            <h4 className="hotel-mobile-cards--title">
                                Grand Deluxe Room
                            </h4>
                            <p className="hotel-mobile-cards--price">
                                290 000 ₽
                            </p>
                        </li>
                        <li className="hotel-mobile-cards__item"></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const HotelMain = connect(
    (state) => {
        return { hotelType: state.AppMainReducer.hotelType };
    },
    (dispatch) => ({
        openPopup: (value) => {
            dispatch({ type: 'OPEN_HOTEL_POPUP', payload: { text: value } });
        },
        toogleHotelPopup: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_HOTEL_SUB', payload: val });
        },
        changeHotelType: (value) => {
            dispatch({ type: 'HOTEL_MODIFY', payload: { type: value } });
        },
    })
)(HotelMainComp);
