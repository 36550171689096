import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const bgStyle = (imgSrc: string) => {
    return { background: `url(${imgSrc})` };
};

interface IActivityProps {
    toogleReserMob: Function;
}

const ActivityComp: React.FC<IActivityProps> = ({ toogleReserMob }) => {
    const [mobIndex, setmobIndex] = useState(1);
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className="activity section">
            <div className="activity-content container">
                <p className="activity-text-block active">
                    <span>Уникальные мероприятия</span>
                    <img
                        src={require('../../../static/images/activity/1.png')}
                        alt="activity"
                        className="activity-text-block--img right"
                    />
                </p>
                <p className="activity-text-block active">
                    <span>
                        / Эксклюзивное приложение с функцией TIME CONTROL /{' '}
                    </span>
                    <img
                        src={require('../../../static/images/activity/2.png')}
                        alt="activity"
                        className="activity-text-block--img right"
                    />
                </p>{' '}
                <p className="activity-text-block active">
                    <span>/ Топ 6 вечеринок всемирного масштаба /</span>
                    <img
                        src={require('../../../static/images/activity/4.png')}
                        alt="activity"
                        className="activity-text-block--img"
                    />
                </p>
                <p className="activity-text-block active">
                    <span>/Ежедневное обновление активностией /</span>
                    <img
                        src={require('../../../static/images/activity/5.png')}
                        alt="activity"
                        className="activity-text-block--img left"
                    />
                </p>
                <div className="activity-halfed-block">
                    <p className="activity-text-block active">
                        <span>/ Восхожение на вулканы /</span>
                        <img
                            src={require('../../../static/images/activity/6.png')}
                            alt="activity"
                            className="activity-text-block--img right"
                        />
                    </p>
                    <p className="activity-text-block active">
                        <span>Серфинг /</span>
                        <img
                            src={require('../../../static/images/activity/7.png')}
                            alt="activity"
                            className="activity-text-block--img left"
                        />
                    </p>
                </div>
                <p className="activity-text-block active">
                    <span>/ Йога и уникальные медитацим /</span>
                    <img
                        src={require('../../../static/images/activity/8.png')}
                        alt="activity"
                        className="activity-text-block--img"
                    />
                </p>
                <p className="activity-text-block active">
                    <span>/ Секретные мерориятия /</span>
                    <img
                        src={require('../../../static/images/activity/9.png')}
                        alt="activity"
                        className="activity-text-block--img"
                    />
                </p>
                <div className="activity-halfed-block">
                    <p className="activity-text-block active">
                        <span>/ Закрытый доступ в vip / </span>
                        <img
                            src={require('../../../static/images/activity/10.png')}
                            alt="activity"
                            className="activity-text-block--img"
                        />
                    </p>
                    <p className="activity-text-block active">
                        <span>Lounge /</span>
                        <img
                            src={require('../../../static/images/activity/11.png')}
                            alt="activity"
                            className="activity-text-block--img left"
                        />
                    </p>
                </div>
                <p className="activity-text-block active">
                    <span>/ Мероприятия для семьи и детей /</span>
                    <img
                        src={require('../../../static/images/activity/12.png')}
                        alt="activity"
                        className="activity-text-block--img right"
                    />
                </p>
                <div className="activity-halfed-block">
                    <p className="activity-text-block active">
                        <span>/ Спортивный досуг / </span>
                        <img
                            src={require('../../../static/images/activity/13.png')}
                            alt="activity"
                            className="activity-text-block--img left"
                        />
                    </p>
                    <p className="activity-text-block active">
                        <span>Русская баня. </span>
                        <img
                            src={require('../../../static/images/activity/14.png')}
                            alt="activity"
                            className="activity-text-block--img"
                        />
                    </p>
                </div>
            </div>
            <div className="activity-mobile-container">
                <div className="activity-mobile-text">
                    <p className="activity-mobile-description">Мы предлагаем</p>
                    <h4 className="activity-mobile-title">
                        Уникальные мероприятия{' '}
                    </h4>
                </div>
                <div className="activity-mobile-nums container">
                    <p className="gallery-image-number">
                        {'0' + mobIndex}
                        <span>/07</span>
                    </p>
                </div>
                <img
                    src={require('../../../static/images/activity/arrows_mob.svg')}
                    alt="arrows"
                    className="activity-mobile-arrows"
                />
                <div className="mobile-gallery-slider">
                    <Slider
                        {...settings}
                        beforeChange={(oldIndex, newIndex) => {
                            setmobIndex(newIndex + 1);
                        }}
                    >
                        <div>
                            <div
                                className="mobile-gallery-slider-item"
                                style={bgStyle(
                                    require('../../../static/images/gallery/3.png')
                                )}
                            ></div>
                        </div>
                        <div>
                            <div
                                className="mobile-gallery-slider-item"
                                style={bgStyle(
                                    require('../../../static/images/gallery/3.png')
                                )}
                            ></div>
                        </div>
                    </Slider>
                </div>
                <button
                    className="mobile-gallery-btn"
                    onClick={() => toogleReserMob(true)}
                >
                    Присоединиться
                </button>
            </div>
        </div>
    );
};

export const Activity = connect(
    (state) => ({}),
    (dispatch) => ({
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
    })
)(ActivityComp);
