import React, { useState } from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { ImagePicker } from '../../components/imagePicker';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const bgStyle = (imgSrc: string) => {
    return { background: `url(${imgSrc})` };
};

interface IGalleryProps {
    isMobile: Boolean;
    toogleReserMob: Function;
}

export const GalleryComp: React.FC<IGalleryProps> = ({
    isMobile,
    toogleReserMob,
}) => {
    const [bgImage, setbgImage] = useState({
        image: require('../../../static/images/gallery/3.png'),
        position: '03',
        text: {
            title: 'Burning man',
            description: 'Мы привезем с собой топ 6 вечеринок планеты',
        },
    });

    const [mobIndex, setmobIndex] = useState(1);

    const setUpNewBg = (position, { text }) => {
        setbgImage({
            image: require(`../../../static/images/gallery/${position}.png`),
            position: '0' + position,
            text,
        });
    };

    const images = [
        {
            src: require('../../../static/images/gallery/sm-1.png'),
            text: {
                title: 'Ibiza',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
        {
            src: require('../../../static/images/gallery/sm-2.png'),
            text: {
                title: 'October fest',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
        {
            src: require('../../../static/images/gallery/sm-3.png'),
            text: {
                title: 'Burning man',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
        {
            src: require('../../../static/images/gallery/sm-4.png'),
            text: {
                title: 'October fest',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
        {
            src: require('../../../static/images/gallery/sm-5.png'),
            text: {
                title: 'New Year Sensation',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
        {
            src: require('../../../static/images/gallery/sm-6.png'),
            text: {
                title: 'Indian holi',
                description: 'Мы привезем с собой топ 6 вечеринок планеты',
            },
        },
    ];

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="gallery section" style={bgStyle(bgImage.image)}>
            <div className="gallery-content--wrapper">
                <div className="gallery-content container">
                    <div className="gallery-text-block">
                        <p className="font-md gallery-description-text">
                            {bgImage.text.description}
                        </p>
                        <h2 className="font-lg gallery-title">
                            {' '}
                            {bgImage.text.title}
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <p className="gallery-image-number">
                        {isMobile ? '0' + mobIndex : bgImage.position}{' '}
                        <span>/07</span>
                    </p>
                </div>
            </div>
            <div className="mobile-gallery-slider">
                <Slider
                    {...settings}
                    beforeChange={(oldIndex, newIndex) => {
                        setmobIndex(newIndex + 1);
                    }}
                >
                    <div>
                        <div
                            className="mobile-gallery-slider-item"
                            style={bgStyle(bgImage.image)}
                        ></div>
                    </div>
                    <div>
                        <div
                            className="mobile-gallery-slider-item"
                            style={bgStyle(bgImage.image)}
                        ></div>
                    </div>
                </Slider>
            </div>
            <button
                className="mobile-gallery-btn"
                onClick={() => toogleReserMob(true)}
            >
                Присоединиться
            </button>
            <ImagePicker onPick={setUpNewBg} images={images} isText />
        </div>
    );
};

export const Gallery = connect(
    (state) => ({
        isMobile: state.AppMainReducer.isMobile,
    }),
    (dispatch) => ({
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
    })
)(GalleryComp);
