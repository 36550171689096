import React from 'react';

interface IImagePickerProps {
    onPick: Function;
    images: Array<IImage>;
    isText?: Boolean;
}

interface IImage {
    src: string;
    text?: {
        title: string;
        description: string;
    };
}

export const ImagePicker: React.FC<IImagePickerProps> = ({
    onPick,
    images,
    isText,
}) => {
    return (
        <div className="gallery-picker">
            {images.map((image, index) => {
                return (
                    <img
                        key={'gallery' + index}
                        src={image.src}
                        alt="galery event"
                        className="gallery-picker-img"
                        onClick={() => {
                            if (isText) onPick(index + 1, image);
                            else onPick(index + 1);
                        }}
                    />
                );
            })}
        </div>
    );
};
