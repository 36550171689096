import React from 'react';
import { connect } from 'react-redux';
import './styles/main.scss';
import { CloseBtn } from '../../others/close-btn/close-btn';
import { Accordeon } from '../../others/accordeon/accordeon';

interface IFaqPopupMob {
    isShow: Boolean;
    toogleFaqMob: Function;
}

const FaqPopupMobComp: React.FC<IFaqPopupMob> = ({ isShow, toogleFaqMob }) => {
    const items = [
        {
            question: ' Включен ли в стоимость авиабилет?',
            answer:
                ' В стоимость включены прекрасные завтраки в ресторане отеля Kempinsky. Включены обеды на активных выездах. Включены вечерние фуршеты на ежедневных вечеринках. Не включены обеды и ужины в ресторанах в отеле.',
        },
        {
            question: ' Включен ли в стоимость авиабилет?',
            answer:
                ' В стоимость включены прекрасные завтраки в ресторане отеля Kempinsky. Включены обеды на активных выездах. Включены вечерние фуршеты на ежедневных вечеринках. Не включены обеды и ужины в ресторанах в отеле.',
        },
        {
            question: ' Включен ли в стоимость авиабилет?',
            answer:
                ' В стоимость включены прекрасные завтраки в ресторане отеля Kempinsky. Включены обеды на активных выездах. Включены вечерние фуршеты на ежедневных вечеринках. Не включены обеды и ужины в ресторанах в отеле.',
        },
    ];
    return (
        <div
            id="faq-popup-mob-scroll"
            className={isShow ? 'faq-popup-m active' : 'faq-popup-m'}
        >
            <div className="faq-popup-m-header">
                <p className="faq-popup-m-logo">Transformator travel</p>
                <CloseBtn
                    className="faq-popup-m-close"
                    onClick={() => {
                        toogleFaqMob(false);
                    }}
                />
            </div>
            <div className="faq-popup-m-content">
                <h4 className="faq-popup-m-title">Часто задаваемые вопросы</h4>
                <Accordeon items={items} />
            </div>
        </div>
    );
};

export const FaqPopupMob = connect(
    (state) => ({
        isShow: state.AppMainReducer.isMobileFaq,
    }),
    (dispatch) => ({
        toogleFaqMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_FAQ', payload: val });
        },
    })
)(FaqPopupMobComp);
