import React from 'react';
import { connect } from 'react-redux';
import './styles/faq-btn.scss';

interface IFaqBtnProps {
    toogleFaqPopup: Function;
}

const FaqBtnComp: React.FC<IFaqBtnProps> = ({ toogleFaqPopup }) => {
    return (
        <button
            className="font-sm rounded-button-md faq-btn"
            onClick={() => {
                toogleFaqPopup(true);
            }}
        >
            faq
        </button>
    );
};

export const FaqBtn = connect(
    (state) => ({}),
    (dispatch) => ({
        toogleFaqPopup: (value) => {
            dispatch({ type: 'TOOGLE_FAQ_POPUP', payload: { value } });
        },
    })
)(FaqBtnComp);
