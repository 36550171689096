import React, { useState, useEffect } from 'react';
import './styles/main.scss';

interface ISelectProps {
    placeholder: string;
    id: string;
    name: string;
    onChange: Function;
    value: string;
    className?: string;
    selectItems: Array<{
        value: any;
        text: string;
    }>;
}

export const Tselect: React.FC<ISelectProps> = ({
    placeholder,
    id,
    name,
    onChange,
    value,
    className,
    selectItems,
}) => {
    const [selectClass, setClass] = useState('t-select');

    const [isActive, setisActive] = useState(false);

    useEffect(() => {
        const body = document.querySelector('body');
        body?.addEventListener('click', () => {
            setClass('t-select');
            setisActive(false);
        });
        return () => {
            body?.removeEventListener('click', () => {
                setClass('t-select');
                setisActive(false);
            });
        };
    }, []);

    return (
        <div
            className={selectClass + ' ' + (className || '')}
            onClick={(e) => {
                e.stopPropagation();

                if (isActive) {
                    setClass('t-select');
                    setisActive(false);
                } else {
                    setisActive(true);
                    setClass('t-select active');
                }
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                className="t-select--icon"
            >
                <circle
                    cx="13.5"
                    cy="13.5"
                    r="13.5"
                    transform="rotate(-90 13.5 13.5)"
                    fill="#616161"
                    className="t-select--icon--circle"
                />
                <path
                    d="M8.21741 12.3262L13.4949 17.6037L18.7725 12.3262"
                    stroke="white"
                    strokeWidth="2"
                    className="t-select--icon--arrow"
                />
            </svg>
            <input
                id={id}
                type="text"
                name={name}
                className="font-md t-select--input"
                readOnly
                value={value}
                placeholder={placeholder}
            />
            <ul
                className="t-select-dropdown"
                onClick={(e) => {
                    e.stopPropagation();
                    setClass('t-select');
                    setisActive(false);
                }}
            >
                {selectItems.map((item) => {
                    return (
                        <li
                            className="font-md t-select-dropdown__item"
                            key={item.value}
                            onClick={() => {
                                onChange(item.value);
                            }}
                        >
                            {item.text}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
