const initialAppState = {
    hotelType: 'first',
    currentAnchor: 'new-year-bali',
    hotelPopup: {
        isShow: false,
        price: '',
        description: '',
        optionsList: [''],
    },
    faqPopup: {
        isShow: false,
        currentBg: null,
        currentText: null,
        currentQuestion: 0,
    },
    reservationPopup: {
        isShow: false,
        currentHotel: null,
    },
    isMobileMenu: false,
    isMobile: window.innerWidth <= 768,
    isReservMob: false,
    isMobileFaq: false,
    isHotelSubMob: false,
    currentSliderPage: 0,
    currentSectionCount: 0,
};

export const AppMainReducer = (state = initialAppState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'HOTEL_MODIFY':
            return { ...state, hotelType: payload.type };
        case 'SET_UP_ANCHOR':
            return { ...state, currentAnchor: payload.anchor };
        case 'CLOSE_HOTEL_POPUP':
            return {
                ...state,
                hotelPopup: {
                    ...state.hotelPopup,
                    isShow: false,
                },
            };
        case 'OPEN_HOTEL_POPUP':
            return {
                ...state,
                hotelPopup: {
                    isShow: true,
                    ...payload.text,
                },
            };
        case 'TOOGLE_FAQ_POPUP':
            return {
                ...state,
                faqPopup: {
                    ...state.faqPopup,
                    isShow: payload.value,
                },
            };
        case 'SET_UP_QUESTION_FAQ_POPUP':
            return {
                ...state,
                faqPopup: {
                    ...state.faqPopup,
                    ...payload.content,
                },
            };
        case 'OPEN_RESERVATION_POPUP':
            return {
                ...state,
                reservationPopup: {
                    ...state.reservationPopup,
                    currentHotel: payload.currentHotel,
                    isShow: true,
                },
            };
        case 'CLOSE_RESERVATION_POPUP':
            return {
                ...state,
                reservationPopup: {
                    ...state.reservationPopup,
                    isShow: false,
                },
            };
        case 'TOOGLE_MOBILE_MENU':
            return {
                ...state,
                isMobileMenu: payload,
            };
        case 'TOOGLE_MOBILE_RESERV':
            return {
                ...state,
                isReservMob: payload,
            };
        case 'TOOGLE_MOBILE_FAQ':
            return {
                ...state,
                isMobileFaq: payload,
            };
        case 'TOOGLE_MOBILE_HOTEL_SUB':
            return {
                ...state,
                isHotelSubMob: payload,
            };
        case 'CHANGE_SLIDER_PAGE':
            return {
                ...state,
                currentSliderPage: payload.page,
            };
        case 'UPDATE_SECTION_COUNT':
            return {
                ...state,
                currentSectionCount: payload.newValue,
            };
        default:
            return state;
    }
};
