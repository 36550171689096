import React, { useState, useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { Header } from '../components/header';
import { NewYearBali } from '../sections/new-year-bali';
import { FaqBtn } from '../components/faq/faq-btn';
import { BaliSub } from '../sections/new-year-bali/bali-sub';
import { Gallery } from '../sections/gallery';
import { HotelMain } from '../sections/hotel';
import { connect } from 'react-redux';
import { EHotelType } from '../../types';
import { HotelPopup } from '../components/popups/hotel-popup/hotelPopup';
import { Activity } from '../sections/activity';
import { FaqPopup } from '../components/popups/faq-popup/faqPopup';
import { ReservationPopup } from '../components/popups/reservation-popup/reservationPopup';
import { MobileMenu } from '../components/mobile/mobile-menu/mobile-menu';
import { HotelSubM } from '../components/mobile/hotel_sub/hotel-sub-m';
import { FaqPopupMob } from '../components/mobile/faq/faq-popup-m';
import { ReservMob } from '../components/mobile/reserv/reserv-m';
import { ProgressBar } from '../components/others/progress-bar/progress-bar';

export interface IHotelProps {
    hotelType: EHotelType;
    changeHotelType: Function;
    setUpAnchor: Function;
    isMobile: Boolean;
    toogleReserMob: Function;
    setPage: Function;
    currentSliderPage: number;
    updateSectionCount: Function;
    currentSectionCount: number;
    currentAnchor: string;
}

const MainComp: React.FC<IHotelProps> = ({
    hotelType,
    changeHotelType,
    setUpAnchor,
    isMobile,
    toogleReserMob,
    currentSliderPage,
    setPage,
    currentSectionCount,
    updateSectionCount,
    currentAnchor,
}) => {
    const [baliSubClass, setbaliSubClass] = useState('');
    const [hotelBtnClass, sethotelBtnClass] = useState('');
    const [count, setcount] = useState(0);
    const [permission, setpermission] = useState(false);

    useEffect(() => {
        let timer;
        if (!isMobile) {
            if (count !== 0) {
                timer = setTimeout(() => {
                    setpermission(true);
                }, 150);
            } else {
                setpermission(false);
            }
        } else {
            setpermission(true);
        }

        return () => clearTimeout(timer);
    }, [count, permission, isMobile]);
    // let count = 0;

    return (
        <div>
            <ProgressBar />
            <Header />
            <MobileMenu />
            <FaqBtn />
            <HotelSubM />
            <FaqPopup />
            <FaqPopupMob />
            <ReservationPopup />
            <ReservMob />
            <HotelPopup />
            <img
                src={require('../../static/images/bali-sub/bg_mob.png')}
                alt="background"
                className={'bali-sub-image-bg mob ' + baliSubClass}
            />
            <button
                className={'hotel-mobile-reservation-btn ' + hotelBtnClass}
                onClick={() => toogleReserMob(true)}
            >
                Забронировать
            </button>
            <ReactFullpage
                //fullpage options
                licenseKey={'234F60EC-A0174D96-8AEF67AE-1799259D'}
                scrollingSpeed={1000} /* Options here */
                anchors={[
                    'new-year-bali',
                    'new-year-bali-sub',
                    'gallery',
                    'hotel',
                    'activity',
                ]}
                normalScrollElements="#faq-popup-mob-scroll"
                scrollOverflow={isMobile}
                fadingEffect={true}
                fixedElement=".faq-popup-m"
                afterLoad={(origin, destination, direction) => {
                    updateSectionCount(destination.index);
                }}
                onLeave={(section, origin, destination, direction) => {
                    if (
                        section.anchor === 'gallery' &&
                        destination === 'down' &&
                        hotelType === 'initial'
                    ) {
                        changeHotelType('scrolled');
                    }
                    if (origin.anchor === 'hotel' && hotelType === 'initial') {
                        changeHotelType('scrolled');
                    }
                    if (
                        section.anchor === 'hotel' &&
                        (destination === 'down' || destination === 'up')
                    ) {
                        changeHotelType('initial');
                    }
                    if (
                        section.anchor === 'new-year-bali-sub' &&
                        destination === 'up' &&
                        currentSliderPage !== 1 &&
                        currentAnchor === 'new-year-bali-sub'
                    ) {
                        setPage(currentSliderPage - 1);
                        return false;
                    }
                    if (
                        section.anchor === 'new-year-bali-sub' &&
                        destination === 'down' &&
                        currentAnchor === 'new-year-bali-sub'
                    ) {
                        setPage(currentSliderPage + 1);
                        setcount(count + 1);
                        if (!permission) return false;
                        else {
                            setcount(0);
                            setpermission(false);
                        }
                        // return false;
                    }

                    if (origin.anchor === 'new-year-bali-sub') {
                        setTimeout(() => {
                            setbaliSubClass('active');
                        }, 1000);
                    }
                    if (section.anchor === 'new-year-bali-sub')
                        setbaliSubClass('');
                    if (origin.anchor === 'hotel') {
                        setTimeout(() => {
                            sethotelBtnClass('active');
                        }, 1000);
                    }
                    if (section.anchor === 'hotel') sethotelBtnClass('');

                    setUpAnchor(origin.anchor);
                }}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <NewYearBali api={fullpageApi} />
                            <BaliSub />
                            <Gallery />
                            <HotelMain />
                            <Activity />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    );
};

export const Main = connect(
    (state) => {
        return {
            hotelType: state.AppMainReducer.hotelType,
            isMobile: state.AppMainReducer.isMobile,
            currentSliderPage: state.AppMainReducer.currentSliderPage,
            currentSectionCount: state.AppMainReducer.currentSectionCount,
            currentAnchor: state.AppMainReducer.currentAnchor,
        };
    },
    (dispatch) => ({
        changeHotelType: (value) => {
            dispatch({ type: 'HOTEL_MODIFY', payload: { type: value } });
        },
        setUpAnchor: (value) => {
            dispatch({ type: 'SET_UP_ANCHOR', payload: { anchor: value } });
        },
        toogleReserMob: (val) => {
            dispatch({ type: 'TOOGLE_MOBILE_RESERV', payload: val });
        },
        setPage: (value) => {
            dispatch({ type: 'CHANGE_SLIDER_PAGE', payload: { page: value } });
        },
        updateSectionCount: (value) => {
            dispatch({
                type: 'UPDATE_SECTION_COUNT',
                payload: { newValue: value },
            });
        },
    })
)(MainComp);
