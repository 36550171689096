import React, { useState, useEffect } from 'react';
import './styles/main.scss';

interface IAccordeonProps {
    items: Array<Iitem>;
}

interface Iitem {
    question: string;
    answer: string;
}

export const Accordeon: React.FC<IAccordeonProps> = ({ items }) => {
    const [AccordeonItems, setAccordeonItems] = useState<NodeListOf<Element>>();
    const [activeItem, setactiveItem] = useState<Array<any>>([]);
    useEffect(() => {
        const accordeonItems = document.querySelectorAll(
            '.accordeon-item-desc'
        );

        setAccordeonItems(accordeonItems);
        for (let i = 0; i < accordeonItems.length; i++) {
            closeItem(accordeonItems[i]);
        }
        return () => {};
    }, []);

    const closeItem = (item) => {
        if (item instanceof HTMLElement) {
            const height = item.offsetHeight;
            item.setAttribute('style', `margin-top: -${height - 1}px`);
        }
    };

    const openItem = (index) => {
        if (AccordeonItems) {
            const item = AccordeonItems[index];
            if (item instanceof HTMLElement) {
                const height = item.offsetHeight;
                if (activeItem.includes(index)) {
                    AccordeonItems[index].setAttribute(
                        'style',
                        `margin-top: -${height - 1}px`
                    );
                    activeItem.splice(
                        activeItem.findIndex((item) => item === index),
                        1
                    );
                    setactiveItem([...activeItem]);
                } else {
                    AccordeonItems[index].setAttribute(
                        'style',
                        `margin-top: 0px`
                    );
                    setactiveItem([...activeItem, index]);
                }
            }
        }
    };

    return (
        <div className="faq-popup-m-accordeon">
            {items.map((item, index) => {
                return (
                    <div
                        className="accordeon-item-wrapper"
                        key={index + 'accordeon-item'}
                        onClick={() => {
                            openItem(index);
                        }}
                    >
                        <div
                            className={
                                activeItem.includes(index)
                                    ? 'accordeon-item'
                                    : 'accordeon-item active'
                            }
                        >
                            <div className="accordeon-item-header">
                                <p className="accordeon-item-text">
                                    {item.question}
                                </p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="27"
                                    height="27"
                                    viewBox="0 0 27 27"
                                    fill="none"
                                    className="accordeon--icon"
                                >
                                    <circle
                                        cx="13.5"
                                        cy="13.5"
                                        r="13.5"
                                        transform="rotate(-90 13.5 13.5)"
                                        fill="#616161"
                                        className="accordeon--icon--circle"
                                    />
                                    <path
                                        d="M8.21741 12.3262L13.4949 17.6037L18.7725 12.3262"
                                        stroke="white"
                                        strokeWidth="2"
                                        className="accordeon--icon--arrow"
                                    />
                                </svg>
                            </div>
                            <p className="accordeon-item-desc">{item.answer}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
