import React, { useState } from 'react';
import { connect } from 'react-redux';
import './style/main.scss';
import { Tinput } from '../../others/t-input/t-input';
import { Tselect } from '../../others/t-select/t-select';
import { CloseBtn } from '../../others/close-btn/close-btn';
import { API } from '../../../../config/api';

interface IReservationPopupProps {
    popupState: {
        isShow: Boolean;
        currentHotel: string;
    };
    closePopup: Function;
}

const ReservationPopupComp: React.FC<IReservationPopupProps> = ({
    popupState,
    closePopup,
}) => {
    const [inputs, setInputs] = useState({
        name: '',
        email: '',
        phone: '',
    });
    const [select, setSelect] = useState('');
    const mainClass = () => {
        if (popupState.isShow) return 'reservation-popup active';
        return 'reservation-popup';
    };

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const r = await API.post('/ADD_NEW_LEAD', {
                title: select,
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                price: 320000,
            });
            console.log('Cigan-log: submitForm -> r', r);
            alert('ok');
        } catch (error) {
            console.log('Cigan-log: submitForm -> error', error);
        }
    };

    const selectItems = [
        {
            text: 'Luxury Three Bedroom Villa',
            value: 'Luxury Three Bedroom Villa',
        },
        { text: 'Grand Deluxe Room', value: 'Grand Deluxe Room' },
        { text: 'Grand Deluxe Lagoon Room', value: 'Grand Deluxe Lagoon Room' },
        {
            text: 'Cliff Private Pool Junior Suite',
            value: 'Cliff Private Pool Junior Suite',
        },
        {
            text: 'The Apurva Prestige Suite',
            value: 'The Apurva Prestige Suite',
        },
        { text: 'One Bedroom Villa', value: 'One Bedroom Villa' },
        { text: 'Two Bedroom Villa', value: 'Two Bedroom Villa' },
    ];
    return (
        <div className={mainClass()}>
            <CloseBtn
                className="reservation-popup-close"
                onClick={() => closePopup()}
            />
            <p className="reservation-popup-logo">Transformator travel</p>
            <div className="reservation-popup-content">
                <form
                    className="reservation-popup-form--wrapper"
                    onSubmit={(e) => submitForm(e)}
                >
                    <div className="reservation-popup-form">
                        <Tinput
                            id="name-input"
                            type="text"
                            placeholder="Имя"
                            value={inputs.name}
                            name="name"
                            className="reservation-popup-form-input"
                            onChange={(e) => {
                                setInputs({
                                    ...inputs,
                                    name: (e.target as any).value,
                                });
                            }}
                        />
                        <Tinput
                            id="email-input"
                            type="text"
                            placeholder="E-mail"
                            value={inputs.email}
                            name="email"
                            className="reservation-popup-form-input"
                            onChange={(e) => {
                                setInputs({
                                    ...inputs,
                                    email: (e.target as any).value,
                                });
                            }}
                        />
                        <Tinput
                            id="phone-input"
                            type="text"
                            mask={'+7(999)-999-99-99'}
                            placeholder="Телефон"
                            value={inputs.phone}
                            name="phone"
                            className="reservation-popup-form-input"
                            onChange={(e) => {
                                setInputs({
                                    ...inputs,
                                    phone: (e.target as any).value,
                                });
                            }}
                        />
                        <Tselect
                            id="hotel-select"
                            value={select}
                            placeholder="Выберите отель"
                            name="hotel-select"
                            onChange={(val) => setSelect(val)}
                            selectItems={selectItems}
                        />
                    </div>
                    <button className="font-md reservation-button">
                        Забронировать
                    </button>
                    <p className="font-sm reservation-popup-personal-data-agreement">
                        Нажимая на кнопку “Забронировать”, <br /> я даю согласие
                        на обработку{' '}
                        <a
                            href="/"
                            className="font-sm reservation-popup-personal-data-agreement__link"
                        >
                            личных данных
                        </a>
                        .
                    </p>
                </form>
            </div>
        </div>
    );
};

export const ReservationPopup = connect(
    (state) => ({ popupState: state.AppMainReducer.reservationPopup }),
    (dispatch) => ({
        closePopup: () => {
            dispatch({
                type: 'CLOSE_RESERVATION_POPUP',
            });
        },
    })
)(ReservationPopupComp);
