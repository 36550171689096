import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './styles/bali-sub.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IBaliSubProps {
    setPage: Function;
    currentPage: Number;
}

const BaliSubComp: React.FC<IBaliSubProps> = ({ setPage, currentPage }) => {
    const slider = React.createRef<any>();

    useEffect(() => {
        slider.current.slickNext();
    }, [currentPage, slider]);
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="bali-sub section">
            <div
                className="bali-sub-content container"
                onClick={() => setPage(2)}
            >
                <img
                    src={require('../../../static/images/bali-sub/grass_up.png')}
                    alt="grass"
                    className="bali-sub-grass-up"
                />
                <img
                    src={require('../../../static/images/bali-sub/grass.png')}
                    alt="grass"
                    className="bali-sub-grass"
                />
                <div className="bali-sub--images-part">
                    <img
                        src={require('../../../static/images/bali-sub/text.svg')}
                        alt="text"
                        className="bali-sub-image-text"
                    />
                    <img
                        src={require('../../../static/images/bali-sub/bg.png')}
                        alt="background"
                        className="bali-sub-image-bg"
                    />
                </div>
                <div className="bali-sub--text-part">
                    <Slider
                        ref={slider}
                        {...settings}
                        beforeChange={(oldIndex, newIndex) => {
                            setPage(newIndex + 1);
                        }}
                    >
                        <div className="bali-sub--text--slider">
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pb">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                            <h3 className="font-lg bali-sub--text--slider__title">
                                Club 500 Bali camp
                            </h3>
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pt-bg">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pt">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                        </div>
                        <div className="bali-sub--text--slider">
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pb">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                            <h3 className="font-lg bali-sub--text--slider__title">
                                Club 500 Bali camp
                            </h3>
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pt-bg">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                            <p className="font-md bali-sub--text--slider__desc bali-sub--text--slider__desc__pt">
                                Эксклюзивный формат путешествия, <br /> который
                                никто в мире ещё не реализовывал. <br /> Мы
                                будем первыми!
                            </p>
                        </div>
                    </Slider>
                </div>
            </div>
            <ul className="bali-sub-mobile-list">
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>

                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>

                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>

                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item">test</li>
                <li className="bali-sub-mobile-list__item">
                    <div className="bali-sub-dote"></div>
                </li>
                <li className="bali-sub-mobile-list__item"></li>
                <li className="bali-sub-mobile-list__item"></li>
                <li className="bali-sub-mobile-list__item"></li>
                <li className="bali-sub-mobile-list__item"></li>
            </ul>
        </div>
    );
};

export const BaliSub = connect(
    (state) => ({
        currentPage: state.AppMainReducer.currentSliderPage,
    }),
    (dispatch) => ({
        setPage: (value) => {
            dispatch({ type: 'CHANGE_SLIDER_PAGE', payload: { page: value } });
        },
    })
)(BaliSubComp);
